import { createReducer, on } from '@ngrx/store';
import { ClearTeamsList, LoadExternalTeamsByUserId, LoadExternalTeamsByUserIdError, LoadExternalTeamsByUserIdSuccess, LoadSeasonGroupsError, LoadSeasonGroupsOne, LoadSeasonGroupsSuccess, LoadSeasonsOne, LoadSeasonsSuccess, LoadSessionsError, LoadSessionsOne, LoadSessionsSuccess, LoadTeamRoles, LoadTeamRolesError, LoadTeamRolesSuccess, LoadTeamsByUserId, LoadTeamsByUserIdError, LoadTeamsByUserIdSuccess, LoadTeamsListSuccess, LoadTeamsOne, LoadTeamsOneError, LoadTeamsOneSuccess } from '@sentinels/state/features/teams/actions';
import { initialTeamsState } from '@sentinels/state/features/teams/state';
import { CurrentState } from '@sentinels/state/state';

export const teamsReducer = createReducer(
  initialTeamsState,
  on(
    LoadTeamsByUserId,
    (state, action) => ({
      ...state,
      state: CurrentState.Loading,
      userId: action.userId,
    }),
  ),
  on(
    LoadTeamsByUserIdSuccess,
    (state, action) => ({
      ...state,
      state: CurrentState.Success,
      teams: action.teams,
    }),
  ),
  on(
    LoadTeamsByUserIdError,
    (state, action) => ({
      ...state,
      state: CurrentState.Error,
      error: action.error,
    }),
  ),
  on(
    LoadExternalTeamsByUserId,
    (state, action) => ({
      ...state,
      userId: action.userId,
    }),
  ),
  on(
    LoadExternalTeamsByUserIdSuccess,
    (state, action) => ({
      ...state,
      externalTeams: action.externalTeams,
    }),
  ),
  on(
    LoadExternalTeamsByUserIdError,
    (state, action) => ({
      ...state,
      error: action.error,
    }),
  ),
    on(
    LoadTeamRoles,
    (state) => ({
      ...state,
    }),
  ),
  on(
    LoadTeamRolesSuccess,
    (state, action) => ({
      ...state,
      roles: action.roles,
    }),
  ),
  on(
    LoadTeamRolesError,
    (state, action) => ({
      ...state,
      error: action.error,
    }),
  ),
  on(
    LoadTeamsListSuccess,
    (state, action) => ({
      ...state,
      teams: action.teams,
    }),
  ),
  on(
    ClearTeamsList,
    (state) => ({
      ...state,
      teams: [],
    }),
  ),
  on(
    LoadTeamsOne,
    (state) => ({
      ...state,
      state: CurrentState.Loading,
    }),
  ),
  on(
    LoadTeamsOneSuccess,
    (state, action) => ({
      ...state,
      teams: [...state.teams, action.team],
      state: CurrentState.Success,
    }),
  ),
  on(
    LoadTeamsOneError,
    (state) => ({
      ...state,
      state: CurrentState.Error,
    }),
  ),
  on(LoadSessionsOne, (state) => ({
    ...state,
    sessionsState: CurrentState.Loading,
  })),
  on(LoadSessionsSuccess, (state, action) => ({
    ...state,
    sessionsState: CurrentState.Success,
    sessions: new Map([...state.sessions, [action.teamId, action.sessions]]),
  })),
  on(LoadSessionsError, (state, action) => ({
    ...state,
    sessionsState: CurrentState.Error,
    error: action.error,
  })),
  on(LoadSeasonsOne, (state) => ({
    ...state,
    seasonsState: CurrentState.Loading,
  })),
  on(LoadSeasonsSuccess, (state, action) => ({
    ...state,
    seasonsState: CurrentState.Success,
    seasons: new Map([...state.seasons, [action.teamId, action.seasons]]),
  })),
  on(LoadSessionsError, (state, action) => ({
    ...state,
    seasonsState: CurrentState.Error,
    error: action.error,
  })),
  on(LoadSeasonGroupsOne, (state) => ({
    ...state,
    seasonGroupsState: CurrentState.Loading,
  })),
  on(LoadSeasonGroupsSuccess, (state, action) => ({
    ...state,
    seasonGroupsState: CurrentState.Success,
    seasonGroups: new Map([...state.seasonGroups, [action.teamId, action.seasonGroups]]),
  })),
  on(LoadSeasonGroupsError, (state, action) => ({
    ...state,
    seasonGroupsState: CurrentState.Error,
    error: action.error,
  })),
);
