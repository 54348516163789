import { ExternalTeam } from '@index/interfaces';
import { GthTeamModel, GthTeamRoleModel, GthTeamSessionModel } from '@sentinels/models';
import { GthTeamSeasonModel } from '@sentinels/models/team-season';
import { GthTeamSeasonGroupModel } from '@sentinels/models/team-season-group';
import { CurrentState } from '@sentinels/state/state';

export interface TeamsState {
  userId: string | null;
  teams: GthTeamModel[];
  externalTeams: ExternalTeam[];
  roles: GthTeamRoleModel[];
  state: CurrentState;
  error: unknown | null;
  sessionsState?: CurrentState;
  sessions?: Map<string, GthTeamSessionModel[]>;
  seasonsState?: CurrentState;
  seasons?: Map<string, GthTeamSeasonModel[]>;
  seasonGroupsState?: CurrentState;
  seasonGroups?: Map<string, GthTeamSeasonGroupModel[]>;
}

export const initialTeamsState: TeamsState = {
  userId: null,
  teams: [],
  externalTeams: [],
  roles: [],
  state: CurrentState.Pending,
  error: null,
  sessionsState: CurrentState.Pending,
  sessions: new Map<string, GthTeamSessionModel[]>(),
  seasonsState: CurrentState.Pending,
  seasons: new Map<string, GthTeamSeasonModel[]>(),
  seasonGroupsState: CurrentState.Pending,
  seasonGroups: new Map<string, GthTeamSeasonGroupModel[]>(),
};
